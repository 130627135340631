<template>
  <div class="community-page">
    <community-header :home="isHome"></community-header>
    <router-view 
      @refreshReBackTop="refreshReBackTop" 
      @resetReBackTop="resetReBackTop">
    </router-view>

    <!-- 回到顶部按钮 -->
    <!-- <back-to-top right="40px" bottom="50px" visibleoffset="200">
      <div class="com-to-top">
        <i class="el-icon-arrow-up"></i>
      </div>
    </back-to-top> -->

    <!-- 返回顶部按钮 -->
    <ReBackTop ref="reBackTop"></ReBackTop>

    <!-- 右下角菜单 -->
    <c-menu></c-menu>
  </div>
</template>

<script>
import CommunityHeader from "@/components/CommunityHeader/index";
import CMenu from "@/components/CMenu/index";
export default {
  components: {
    CommunityHeader,
    CMenu,
  },
  data() {
    return {};
  },
  computed: {
    isHome() {
      return this.$route.path == "/community/home";
    },
    isCollectionListRoute(){
      return this.$route.path == "/community/collectionList";
    }
  },
  mounted(){
    //厨师哈ReBackTop
    this.$refs.reBackTop.init();
  },
  beforeDestroy(){
    //销毁ReBackTop
    this.$refs.reBackTop.destroy();
  },
  methods: {
    //1,瀑布流页面HWaterfall组件会自动给页面#app添加auto高度, 只需要监听window的滚动；
    //2,community/collectionList页不是瀑布流，采用无限滚动加载，需要监听滚动内容的高度固定的父类#app
    refreshReBackTop($el){
      this.$nextTick(()=>{
        this.$refs.reBackTop.refresh($el);
      })
    },
    //community/collectionList页销毁时，重置ReBackTop的target为默认window
    resetReBackTop(){
      console.log('resetReBackTopTarget')
      this.$refs.reBackTop.refresh();
    }
  },
};
</script>