<template>
    <div class="community-header">
        <div class="fixed-header">
            <div class="com-header">
                <div class="header-left">
                    <!-- @click="handleToAnchor('one')" -->
                    <div class="logo" @click="$router.push('/community/collectionList')">
                        <img src="../../assets/images/logo.png" alt="" />
                        <!-- <span>数字化资源云平台</span> -->
                    </div>
                    <ul class="menu">
                        <li @click="$router.push('/community/collectionList')" :class="{ active: $route.path == '/community/collectionList' }">
                            精选作品
                        </li>
                        <li @click="$router.push('/community/workList')" :class="{ active: $route.path == '/community/workList' }">所有作品</li>
                        <li @click="$router.push('/community/designerList')" :class="{ active: $route.path == '/community/designerList' }">设计师</li>
                    </ul>
                    <div class="search-container">
                        <el-input
                            placeholder="搜索您喜欢的"
                            v-model.trim="input"
                            class="input-with-select"
                            clearable
                            @clear="handleSearch"
                            @keyup.enter.native="handleSearch"
                        >
                            <el-select v-model="select" slot="prepend" placeholder="请选择">
                                <el-option :label="item.label" :value="item.value" v-for="(item, index) in options" :key="index"></el-option>
                            </el-select>
                            <i class="el-input__icon el-icon-search" slot="suffix" @click.stop="handleSearch"></i>
                        </el-input>
                        <el-select
                            class="search-type-select"
                            v-model="searchType"
                            placeholder="请选择显示类型"
                            v-if="isShow"
                            @change="handleSearchType"
                        >
                            <el-option label="显示所有" value=""></el-option>
                            <el-option label="显示图片" value="0"></el-option>
                            <el-option label="显示模型" value="1"></el-option>
                        </el-select>
                    </div>
                </div>
                <div class="header-right">
                    <el-dropdown trigger="click" @command="handleToggleRole">
                        <div class="user">
                            <el-avatar :size="32" :src="photo || circleUrl"></el-avatar>
                            <span class="user-name">{{ username }}</span
                            ><i class="el-icon-arrow-down el-icon--right"></i>
                        </div>
                        <el-dropdown-menu slot="dropdown">
                            <!-- <el-dropdown-item command="workspace">工作台</el-dropdown-item>
              <el-dropdown-item command="personal">个人中心</el-dropdown-item>
              <el-dropdown-item command="manager" v-if="admin"
                >管理中心</el-dropdown-item
              > -->
                            <el-dropdown-item command="exit">退出登录</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                </div>
            </div>
            <!-- <div class="com-banner" :class="{ 'sub-banner': !home }">
                <img src="../../assets/images/community/community_header_title.png" alt="" v-if="!home" />
            </div> -->

            <!-- <div class="banner-content" v-else>
                <div class="title">欢迎来到属于您的设计空间</div>
            </div> -->
        </div>
    </div>
</template>

<script>
import { EventBus } from "@/EventBus";
export default {
    data() {
        return {
            circleUrl: "https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png",
            input: "", // 作品集和设计师搜索
            input1: "", // 作品搜索
            select: "2",
            username: "",
            photo: "",
            admin: false,
            options: [
                { label: "搜索作品集", value: "2" },
                { label: "搜索作品", value: "1" },
                { label: "搜索设计师", value: "3" }
            ],
            searchType: "", // 显示类型
            isShow: false,
            routeArr: ["/community/collectionList", "/community/collection", "/community/workList"]
        };
    },
    props: {
        show: {
            type: Boolean,
            default: true
        },
        home: {
            type: Boolean,
            default: false
        }
    },
    watch: {
        home: {
            handler(val) {
                console.log(val);
            },
            deep: true,
            immediate: true
        },
        $route: {
            handler(val) {
                console.log(val);
                let path = val.path;
                // let query = val.query.keywords;
                this.input = val.query.keywords ? val.query.keywords : "";
                this.searchType = val.query.type ? val.query.type : "";
                this.isShow = this.routeArr.some(item => path.includes(item));
                console.log(this.isShow);
                switch (path) {
                    case "/community/collectionList":
                        this.select = "2";
                        this.options = [
                            { label: "搜索作品集", value: "2" },
                            { label: "搜索作品", value: "1" },
                            { label: "搜索设计师", value: "3" }
                        ];
                        break;
                    case "/community/workList":
                        this.select = "1";
                        this.options = [
                            { label: "搜索作品", value: "1" },
                            { label: "搜索作品集", value: "2" },
                            { label: "搜索设计师", value: "3" }
                        ];
                        break;
                    case "/community/designerList":
                        this.select = "3";
                        this.options = [
                            { label: "搜索设计师", value: "3" },
                            { label: "搜索作品集", value: "2" },
                            { label: "搜索作品", value: "1" }
                        ];
                        break;
                }
            },
            deep: true,
            immediate: true
        }
    },
    created() {
        console.log(this.$route);
        let userInfo = JSON.parse(localStorage.getItem("userInfo"));
        let nickname = localStorage.getItem("nickname");
        if (nickname) this.username = localStorage.getItem("nickname");
        this.admin = userInfo.admin;
        this.photo = userInfo.photo;
    },
    methods: {
        handleToAnchor(data) {
            this.input = "";
            this.select = "2";
            this.$router.push("/community/home");
            EventBus.$emit("gotoAnchor", data);
        },
        // 切换角色或退出登录
        handleToggleRole(command) {
            console.log(command);
            if (command == "exit") {
                // 退出登录，清空localStorage
                localStorage.clear();
                this.$router.replace("/login");
                // 清除vuex中的数据
                // this.$store.commit("auth/resetState");
            } else if (command == "personal") {
                this.$router.push("/designer/home");
            } else if (command == "workspace") {
                this.$router.push("/home");
            } else if (command == "manager") {
                this.$router.push("/manager");
            }
        },
        // 搜索作品集或设计师
        handleSearch() {
            console.log(this.input);
            // console.log(this.searchType);
            // this.searchType = "";
            switch (this.select) {
                case "1":
                    // 搜索作品
                    this.$router.push("/community/workList?keywords=" + this.input + "&type=" + this.searchType);
                    break;
                case "2":
                    // 搜索作品集
                    this.$router.push("/community/collectionList?keywords=" + this.input + "&type=" + this.searchType);
                    break;
                case "3":
                    // 搜索设计师
                    this.$router.push("/community/designerList?keywords=" + this.input);
                    break;
            }
        },
        // 显示所有，显示作品、显示模型
        handleSearchType() {
            let path = this.$route.path;
            this.$router.push(path + "?keywords=" + this.input + "&type=" + this.searchType);
        },
        // 搜索作品
        handleSearchWork() {
            // console.log(this.input1);
            EventBus.$emit("searchWork", this.input1);
        }
    }
};
</script>

<style lang="less">
@import "../../assets/style/base.less";
.community-header {
    padding-top: 66px;
    .fixed-header {
        width: 100%;
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3000;
    }
    .com-header {
        padding: 0 50px;
        height: 66px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        background-color: #fff;
        box-shadow: 0px 0px 18px 0px rgba(163, 145, 126, 0.19);
        // box-shadow: 0px 0px 13px 0px rgba(105, 105, 105, 0.15);

        .header-left {
            flex: 1;
            height: 100%;
            display: flex;
            align-items: center;
            margin-right: 30px;

            .logo {
                height: 100%;
                display: flex;
                align-items: center;
                margin-right: 50px;
                cursor: pointer;

                > img {
                    width: 100px;
                    transform: scale(1.5);
                }

                > span {
                    font-size: 18px;
                    color: #464646;
                }
            }

            .menu {
                display: flex;

                > li {
                    padding: 0 20px;
                    cursor: pointer;

                    // color: #464646;
                    color: #999999;

                    &.active,
                    &:hover {
                        transform: scale(1.1);
                        // font-size: 22px;
                        color: @mainColor;
                    }
                }
            }

            .search-container {
                display: flex;
                flex: 1;
                margin-left: 30px;
                margin-right: 20px;

                .el-input__inner {
                    box-sizing: border-box;
                    padding-left: 5px;
                    padding-right: 20px;
                    // height: 36px;
                    // line-height: 36px;

                    font-weight: normal;
                    font-stretch: normal;
                    border-radius: 0;
                    border: transparent;
                    color: #3c3c3c;
                    background-color: #efefef;

                    &:focus {
                        outline: none !important;
                        border-color: transparent !important;
                    }

                    &:hover {
                        outline: none !important;
                    }
                }

                .el-input__icon {
                }

                .el-select {
                    .el-input__inner {
                        color: #3c3c3c;
                        text-align: center;
                    }
                }

                .input-with-select {
                    max-width: 600px;
                    border-radius: 4px;
                    border: solid 1px @mainColor;
                    overflow: hidden;

                    .el-input-group__prepend {
                        width: 120px;
                        background-color: #fff;
                        position: relative;
                        border-radius: 4px 0 0 4px;
                        color: #3c3c3c;
                        border-right: transparent;
                        border-right: 1px solid rgba(0, 0, 0, 0.2);
                    }

                    .el-select {
                        display: block;

                        .el-input.is-focus .el-input__inner {
                            border-color: transparent !important;
                            text-align: center;
                        }
                    }

                    > .el-input__inner {
                        padding-left: 25px;
                    }

                    .el-input__icon {
                        &.el-select__caret {
                            width: 20px;
                            font-size: 16px;
                            color: #999999;
                        }

                        &.el-icon-search {
                            color: #999999;
                            font-weight: bold;
                            width: 60px;
                            cursor: pointer;
                        }
                    }
                }

                .search-type-select {
                    width: 160px;
                    margin-left: 10px;
                    border-radius: 4px;
                    overflow: hidden;
                    border: solid 1px @mainColor;

                    .el-input__icon.el-select__caret {
                        color: #828282;
                        font-size: 16px;
                    }
                }

                .work-search-input {
                    border-radius: 4px;

                    .el-input__inner {
                        border-radius: 4px;
                        padding-left: 4px;
                    }
                }
            }
        }

        .header-right {
            cursor: pointer;
            height: 100%;
            display: flex;
            align-items: center;

            .user {
                display: flex;
                align-items: center;
                margin-right: 40px;
                > span {
                    margin-left: 10px;
                    color: #999999;
                }
            }

            .tongzhi {
                // margin: 0 40px;
                margin-right: 40px;

                .icon-tongzhi {
                    color: #a0a0a0;
                    // font-size: 22px;
                    cursor: pointer;
                }
            }
            .btn-text {
                position: absolute;
                left: 50%;
                top: 50%;
                transform: translate(-50%, -50%);
                z-index: 2;
            }
            .work-btn {
                width: 74px;
                height: 30px;
                background-color: #8565f6;
                border-color: #8565f6;
                color: #fff;
                font-size: 14px;
                position: relative;
                &:hover {
                    &:before {
                        content: "";
                        position: absolute;
                        width: 100%;
                        height: 100%;
                        left: 0;
                        top: 0;
                        transform: translateX(-0.5px);
                        background-color: rgba(0, 0, 0, 0.1);
                    }
                }
            }
            .person-btn {
                width: 74px;
                height: 30px;
                background-color: #fff;
                border-color: #ccc;
                color: #3c3c3c;
                font-size: 14px;
                position: relative;
                &:hover {
                    &:before {
                        content: "";
                        width: 100%;
                        height: 100%;
                        position: absolute;
                        left: 0;
                        top: 0;
                        background-color: rgba(0, 0, 0, 0.1);
                        transform: translateX(-0.5px);
                    }
                }
            }
        }
    }

    .com-banner {
        &.home-banner {
            height: 400px;
            background: url("../../assets/images/community/bg.png") no-repeat center;
            background-size: 100% 100%;
        }
        &.sub-banner {
            height: 80px;
            background: url("../../assets/images/community/community_header_bg.png") no-repeat center;
            background-size: 100% 100%;
        }
        display: flex;
        justify-content: center;
        align-items: center;
        &.shrink {
            height: 400px;
        }
        .banner-content {
            .title {
                font-size: 34px;
                color: #fff;
                font-weight: bold;
                text-align: center;
                margin-bottom: 40px;
                letter-spacing: 4px;
                // opacity: 0.8;
            }

            .search-container {
                width: 660px;
                display: flex;

                .input-with-select {
                    .search-input-select {
                        position: absolute;
                        top: 0;
                        left: 0;
                        width: 100%;
                        height: 100%;
                        margin: 0;
                        .el-input {
                            width: 100%;
                        }
                    }
                    .el-input__inner {
                        height: 38px;
                        line-height: 38px;
                        border: none;
                        color: #6f6f6f;
                        font-size: 16px;
                    }

                    .el-input__icon {
                        line-height: 38px;

                        &.el-select__caret {
                            color: #828282;
                        }

                        &.el-icon-search {
                            color: #595959;
                            font-weight: bold;
                            width: 60px;
                            font-size: 24px;
                            cursor: pointer;
                        }

                        .el-input-group__prepend {
                            width: 140px;
                            background-color: #fff;
                            position: relative;

                            &:after {
                                content: "";
                                position: absolute;
                                top: 20%;
                                right: 0;
                                width: 1px;
                                height: 60%;
                                background-color: #dfdfdf;
                            }
                        }
                    }
                }

                .hot-words {
                    padding: 15px 5px;
                    font-size: 15px;
                    color: #fff;
                    display: flex;
                    opacity: 0.9;

                    > span {
                        font-weight: bold;
                    }

                    .words {
                        display: flex;

                        > li {
                            cursor: pointer;
                            padding: 0 15px;
                            opacity: 0.8;

                            &:hover {
                                color: #8565f6;
                            }
                        }
                    }
                }
            }
        }
    }
}
</style>
